import React , { useState, useEffect }from 'react';

import "./ResourcesContent.css";

function ResourcesContent() {

  const [patientAccess, setPatientAccess] = useState(false);
  const [enteredPasscode, setEnteredPasscode] = useState('');

  //Check stored access and update component state
  useEffect(()=>{
    let persistedAccessValue = checkForPersistedAccess();
    setPatientAccess(persistedAccessValue);
  },[]);

  //Ensure any changes to patientAccess are persisted
  useEffect(() => {
    sessionStorage.setItem("patientAccess", patientAccess.toString())
  }, [patientAccess]);

  function checkForPersistedAccess() : boolean
  {
    const accessBoolString = sessionStorage.getItem("patientAccess");
    let accessBool = false;
    if(accessBoolString && (JSON.parse(accessBoolString) === true)){
      console.log("Found a previous session with access!");
      accessBool = true;
    }else{
      console.log("Could not find a previous session with access.");
    }
    return accessBool
  }


  function onPasscodeTextChanged(e:any)
  {
    console.log(e.target.value);
    setEnteredPasscode(e.target.value);
  }

  function onPasscodeSubmitted()
  {
     console.log("Entered Passcode: ", enteredPasscode);
    if(enteredPasscode === "2Bhealthy"){
      //Log user in
      setPatientAccess(true);
      //Store this access in local storage

    }else{
      setPatientAccess(false);
    }
  }

  function renderPatientContent()
  {
    if(patientAccess)
    {
      return(
        <ul>            
          <li><a href="/files/IndexedRecipes.pdf">📄Recipe List (Gluten-free, egg-free, dairy-free, anti-Candida)</a></li>
          <li><a href="/files/EDS-Alternative-Foods.pdf">📄EDS Alternative Foods</a></li>
          <li><a href="/files/EDSINFO-AntiCandida.pdf">📄EDS Info</a></li>
          <li><a href="/files/Fertility-Seed-Snacks.pdf">📄Fertility Seed Snacks</a></li>
          <li><a href="/files/Food-Combining.pdf">📄Food Combining</a></li>
          <li><a href="/files/Low-Purine-Diet.pdf">📄Low Purine Diet</a></li>
          <li><a href="/files/PregnancyChemicalsToAvoid.pdf">📄Chemicals to Avoid During Pregnancy</a></li>
        </ul>
  
      ); 
    }
  }

  function renderPatientAccessForm()
  {
    return(
      <form onSubmit={onPasscodeSubmitted}>
      <label>
        <p>To view content available only to patients, please input the passcode: </p>
        <input className="patientCodeInput" type="text" placeholder="Enter passcode" name="patientcode" onChange={onPasscodeTextChanged}/>
      </label>
      <input className="patientCodeSubmit" type="submit" value="Submit" />
    </form>

    );
  }

  return (
    <div className="ResourcesContentContainer">
      <div className="ResourcesInnerContent">
        <div className="ResourcesIntroParagraph">
          <h3>Published Work</h3>
          <ul>
            <li><a id="pr-beeVenom" href="https://ndnr.com/pain-medicine/chronic-tick-borne-infections-relief-using-bee-venom-treatment/">🔗Chronic Tick-borne Infections: Relief Using Bee Venom Treatment?<em>Naturopathic News & Review (July 2017)</em></a></li>
            <li><a id="pa-fightFatigueNaturally" href="http://www.alive.com/health/fight-fatigue-naturally/">🔗Fight Fatigue Naturally - <em>Alive Magazine (Jan 2017)</em></a></li>
            <li><a id="pr-insomniaTreatment" href="http://ndnr.com/anxietydepressionmental-health/an-unexpected-insomnia-treatment/">🔗An Unexpected Insomnia Treatment - <em>Naturopathic News & Review (March 2016)</em></a></li>
            <li><a id="pr-hormonalTriad" href="http://ndnr.com/e-version/aug14/aug14.pdf.html">🔗Beyond the Hormonal Triad - <em>Naturopathic Doctor News & Review (August 2015, Page 19)</em></a></li>		
          </ul>
          <div className="ResourcesPatientOnlyContainer">
            <h3>Patients Only</h3>
            {renderPatientAccessForm()}
            {renderPatientContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourcesContent;
