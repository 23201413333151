import React from 'react';
import YouTubePlayer from './YouTubePlayer';
import "./Content.css";
//Homepage

function Content() {
  return (
    <div className="ContentContainer">
      <div className="ContentInnerContent">
        <div className="ContentIntroParagraph">
        <p>
        Dr Hallee is located at Meditrine Naturopathic Clinic, a joint clinic with A New Leaf, in Langley, BC. For more information, see <a href='https://www.anewleafnaturopathic.com/'>https://www.anewleafnaturopathic.com/</a> or <a href='https://www.meditrine.ca/'>https://www.meditrine.ca/</a> 
        </p>
        </div>
        <div className="VideoContainer">
          <YouTubePlayer needsSidePadding={true} ID="wLFQn80FJgA"/>
        </div>
      </div>
    </div>
  );
}

export default Content;
