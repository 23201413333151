import React, { useState } from 'react';
import "./ContactContent.css";

function ContactContent() {

  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");

  function onContactFormSubmitted(e:any)
  {
    e.preventDefault();
    console.log(e);
    //Build up email Post for Meditrine
  }

  function onNameChanged(e:any)
  {
    setContactName(e.target.value);
  }

  function onPhoneChanged(e:any){
    setContactPhone(e.target.value);
  }

  function onEmailChanged(e:any){
    setContactEmail(e.target.value);
  }

  function onMessageChanged(e:any){
    setContactMessage(e.target.value);
  }

  function renderContactClinicForm()
  {
    return(
      <>
        Please feel free to send your questions using the form below.
        <p>(All fields mandatory)</p>
        <form onSubmit={onContactFormSubmitted}>
          <div className="contactFormInputSingleLine">
            Name
            <input className="contactName" type="text" placeholder="Your name" name="Contact Name" onChange={onNameChanged}/>
          </div>
          <div className="contactFormInputSingleLine">
            Phone
            <input className="contactPhone" type="text" placeholder="Your phone number" name="Contact Phone" onChange={onPhoneChanged}/>
          </div>
          <div className="contactFormInputSingleLine">
            Email
            <input className="contactEmail" type="text" placeholder="Your email" name="Contact Email" onChange={onEmailChanged}/>
          </div>
          <div className="contactFormInputSingleLine">
            What's your question?
            <input className="contactMessage" type="text" placeholder="Please share your question with me an I'll do my best to answer" name="Contact Message" onChange={onMessageChanged}/>
          </div>
          <div className="contactSubmitContainer">
            <input className="contactFormSubmit" type="submit" value="Submit" />
          </div>
        </form>
      </>
    );
  }

  return (
    <div className="ContactContentContainer">
      <div className="ContactInnerContent">
        <div className="ContactIntroParagraph">
        <h3>How to Connect</h3>
        Either by <b>phone</b> <span className="contactButton"><a href="tel:6045345756">604-534-5756</a></span>
         or by <b></b>email <span className="contactButton"><a href="mailto:meditrineclinic@mac.com">meditrineclinic@mac.com</a></span>
         <span id="contactClipboardButton" className="contactClipboardButton" onClick={() => {
           var element = document.getElementById('contactClipboardButton');
           if(element){
            element.innerHTML = "☑️ Email address copied";
           }

          navigator.clipboard.writeText("meditrineclinic@mac.com")}
          }
        >☐ Copy email address</span>

        <h3>Where are you located?</h3>
          I'm located at the <strong>Meditrine Naturopathic Medical Clinic</strong>
          <div className="ContactMapContainer">
            <iframe style={{border: "5px solid #28464b"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2612.4956516231146!2d-122.61285488339074!3d49.09622367931163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485ce43406f78e5%3A0xd7b5f7e43a6e055c!2sMeditrine%20Naturopathic%20Clinic!5e0!3m2!1sen!2sca!4v1616631752216!5m2!1sen!2sca" width="600" height="450" loading="lazy"></iframe>
          </div>
          <div className="ContactClinicFormContainer">
            {/* {renderContactClinicForm()} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactContent;
