import React from 'react';
import "./LandingBanner.css";
import MoniqueImage from "../assets/BannerLandscapeTransparent.png";
import NDLogo from "../assets/NDLogo.png";

function LandingBanner() {
  return (
    <div className="LandingBanner">
      <div className="LandingBannerContentContainer">
        <div className="MoniqueImgContainer">
          <img className="MoniqueImg" src={MoniqueImage} alt="Dr Monique Hallee, ND"/>
          <div className="HeaderTextContainer">
              <img src={NDLogo} alt="Dr Hallee ND Logo" className="NDLogo"/>
              <div>Dr Monique Hallee ND</div>
              <div className="HeaderSlogan"><em>"Healthcare at its roots"</em></div>
          </div> 
        </div>
      </div>
    </div>
  );
}

export default LandingBanner;
