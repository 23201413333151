import React from 'react';
import './App.css';
import ResourcesContent from '../components/ResourcesContent';
import Footer from '../components/Footer';
import LandingBanner from '../components/LandingBanner';
import Nav from '../components/Nav';


function Resources() {
  return (
    <div className="App">
      <Nav/>
      <LandingBanner/>
      <ResourcesContent/>
      <Footer/>
    </div>
  );
}

export default Resources;
