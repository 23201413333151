import React from "react";
import "./YouTubePlayer.css";

type YouTubeProps = 
{
    ID:string,
    needsSidePadding?:boolean
}

function YouTubePlayer({ID, needsSidePadding} : YouTubeProps)
{
    return(
        <div className={(needsSidePadding && needsSidePadding === true)?"YouTubePlayerSidePadding":"YouTubePlayer"}>
        <iframe
            title="Dr Hallee"
            src={`https://www.youtube.com/embed/${ID}`}
            frameBorder="0"
        />
      </div>
    );
}

export default YouTubePlayer;