import React, {useEffect} from 'react';
import "./Nav.css";
import {Link} from 'react-router-dom';

function Nav() {
  useEffect(() => {
  
    return () => {

    }
  }, []);
  

  return (
    <div onClick={_onMenuClick} className="Nav">
      <Link className="menuLink" to="/"><div className="menuItem">Home</div></Link>
      <Link className="menuLink" to="/about"><div onClick={_onAboutClicked} className="menuItem">About</div></Link>
      <Link className="menuLink" to="/resources"><div onClick={_onResourcesClicked} className="menuItem">Resources</div></Link>
      <Link className="menuLink" to="/contact"><div onClick={_onContactClicked} className="menuItem">Contact</div></Link>

    </div>
  );
}

function _onMenuClick()
{
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

function _onAboutClicked()
{
  console.log("About Clicked!");
}

function _onResourcesClicked()
{
  console.log("Resources Clicked!");
}

function _onContactClicked()
{
  console.log("Contact Clicked!");
}

export default Nav;
