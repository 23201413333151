import React from 'react';
import AboutMoniqueDiagram from "../assets/AboutMoniqueDiagram.png";
import "./AboutContent.css";

function AboutContent() {
  return (
    <div className="AboutContentContainer">
      <div className="AboutInnerContent">
        <div className="AboutIntroParagraph">
          <p>Dr Hallee ND has had a fascination with the miraculous workings of the human body from a very young age. She's always felt a calling to help others but eventually settled on Naturopathic medicine after being convinced of it from her global experiences in both study and work. Dr Hallee also has additional certifications in Bowen therapy, acupuncture, pharmacy, IV treatments and chelation therapy which have helped enrich the level of care for her patients over the last decade.
          </p>
        </div>
        <div className="AboutDiagramContainer">
          <img src={AboutMoniqueDiagram} className="AboutMoniqueDiagram" alt="About Dr Hallee"/>
          <p>
          1 Corinthians 3:16-17 & 6:19-20
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutContent;
