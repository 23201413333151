import React from "react";
import "./Footer.css";
import FaltekLogo from "../assets/Faltek_Logo.png";

function Footer()
{
    return(
        <div className="Footer">
            A <img src={FaltekLogo} alt="Faltek Games" className="FaltekLogo"/>website.  Thank you to Jesse Faltus for the videography and photography; and my friends, family, and loving parents for everything else.
        </div>
    )
}

export default Footer;