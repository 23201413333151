import React from 'react';
import './App.css';
import Content from './../components/Content';
import Footer from './../components/Footer';
import LandingBanner from './../components/LandingBanner';
import Nav from './../components/Nav';


function App() {
  return (
    <div className="App">
      <Nav/>
      <LandingBanner/>
      <Content/>
      <Footer/>
    </div>
  );
}

export default App;
