import React from 'react';
import './About.css';
import AboutContent from '../components/AboutContent';
import Footer from '../components/Footer';
import LandingBanner from '../components/LandingBanner';
import Nav from '../components/Nav';


function About() {
  return (
    <div className="App">
      <Nav/>
      <LandingBanner/>
      <AboutContent/>
      <Footer/>
    </div>
  );
}

export default About;
